<template>
	<div class="helpConter">
		<el-menu :default-active="defaultActive" :default-openeds="defaultOpen" class="el-menu-vertical-demo left"
			:unique-opened="true">
			<el-submenu :index="index" v-for="(item, index) in navLsit" :key="index">
				<template slot="title">
					<i class="el-icon-location"></i>
					<span>{{ item.title }}</span>
				</template>
				<el-menu-item :index="index_sec" v-for="(child, index_sec) in item.articleList" :key="index_sec"
					@click="currentIndex(child.id)">{{ child.title }}</el-menu-item>
			</el-submenu>
		</el-menu>
		<div class="right">
			<div class="title">{{ articleTitle }}</div>
			<div class="conter" v-html="articleContent"></div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters,
		mapActions
	} from 'vuex';
	import {
		getArticleDetails
	} from '@api/user';
	export default {
		name: 'HelpCenter',
		data() {
			return {
				navLsit: [],
				// articleInfo: {},
				articleTitle: '',
				articleContent: '',
				add_time: '',
				childId: '',
				id: '',
				defaultActive: 0
			};
		},
		computed: {
			...mapGetters(['getNavList'])
		},
		watch: {
			$route() {
				let that = this;
				that.id = that.$route.query.id;
				that.childId = that.$route.query.childId;
				that.navLsit.forEach(item => {
					if (that.id == item.id) {
						that.defaultActive = that.navLsit.indexOf(item);
						item.articleList.forEach(child => {
							if (that.childId == child.id) {
								that.index_sec = item.articleList.indexOf(child)
							}
						});
					}
				});
				that.articleDetails();
			},
			getNavList(val) {
				let that = this;
				that.navLsit = val;
			}
		},
		mounted() {
			let that = this;
			that.navLsit = that.getNavList;
			if (that.$route.query.childId) {
				that.childId = that.$route.query.childId;
				that.articleDetails();
			}
		},
		methods: {
			...mapActions(['articleCategory']),
			currentIndex(id) {
				let that = this;
				that.childId = id;
				that.articleDetails();
			},
			articleDetails: function() {
				let that = this;
				getArticleDetails(that.childId).then(res => {
					that.articleInfo = res.data;
					that.articleContent = that.replaceTextFn(res.data.content, '国际免税店', that.$config.appName);
					that.articleTitle = res.data.title;
					that.add_time = res.data.add_time.trim().split(' ')[0];
				});
			},

			replaceTextFn(content, originText, targetText) {
				const reg = /(?<=>)(.|\s)*?(?=<\/?\w+[^<]*>)/g
				const tmpContent = `<div>${content}</div>`
				// let count = 0
				const resContent = tmpContent.replace(reg, s => {
					return s.replace(new RegExp(originText, 'g'), () => {
						// count = count + 1
						return targetText
					})
				})
				return resContent.substring(5, resContent.length - 6)
			}
		}
	};
</script>

<style lang="less" scoped>
	.helpConter {
		width: 1232px;
		margin: 20px auto;
		display: flex;

		.left {
			width: 200px;
		}

		.right {
			flex: 1;
			margin-left: 15px;
			background: #fff;
			padding: 0 24px;

			.title {
				width: 100%;
				height: 50px;
				font-size: 16px;
				line-height: 50px;
				color: #333;
				font-weight: 700;
				border-bottom: 1px solid #fafafa;
				margin-bottom: 1px;
			}
		}
	}
</style>
